.orderable-th .button-order {
  visibility: hidden;
}

.orderable-th:hover .button-order,
.orderable-th.orderable-active .button-order {
  visibility: visible;
}

ul.rc-pagination {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 19px;
}