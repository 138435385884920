nav.sidebar a{
  position: relative;
}

nav.sidebar a.active, 
nav.sidebar a:hover {
  color: var(--chakra-colors-primary-500);
  background-color: rgba(26, 75, 174, 0.1)
}

nav.sidebar a.active::before,
nav.sidebar a:hover::before {
  content: '';
  background-color: var(--chakra-colors-primary-500);
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px 16px 8px 0px;
}