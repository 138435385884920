ul.rc-pagination {
  display: flex;
  align-items: center;
  list-style-type: none;
  background-color: #F3F2F7;
  border-radius: 999px;
  gap: 4px;
}

.chakra-ui-dark ul.rc-pagination {
  background-color: #4A5568;
}

ul.rc-pagination .rc-pagination-jump-prev,
ul.rc-pagination .rc-pagination-jump-next {
  display: none;
}